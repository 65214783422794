<template>
  <div name="index">
    <div class="container" style="margin: 0 10px;">

      <!-- <div class="title">欢迎来到</div>
      <div>阜康码上巡</div> -->

      <div class="title">
        <!-- <div class="title__left"></div>
        <div class="title__center">欢迎来到·阜康码上巡察</div>
        <div class="title__right"></div> -->
        <img class="title__img" src="../../assets/codeUpPatrol/1.png" alt="">
        <span class="title__text"> 欢迎来到 阜康码上巡察 </span>
        <img class="title__img" src="../../assets/codeUpPatrol/2.png" alt="">
      </div>

      <Box title="巡察信息">
        <div style="width: 100%;">
          <van-cell title="巡察组" value="七届市委第八轮巡察" title-class="title-custom-style" />
          <van-cell title="巡察时间" value="2024-09-25至2024-11-15" title-class="title-custom-style" />
          <van-cell title="信访时间" value="2024-09-25至2024-11-15" title-class="title-custom-style" />
          <van-cell title="巡察对象">
            <div slot="label" class="patrol-object">
              <div class="patrol-object__item"> 第一巡察组：上户沟乡黄山中心村、九运街镇八运泉村、九运街镇古城中心村；</div>
              <div class="patrol-object__item"> 第二巡察组：上户沟乡阿克木那拉村、九运街镇新湖中心村、城关镇鱼尔沟中心村；</div>
              <div class="patrol-object__item"> 第三巡察组：上户沟乡幸福路村、上户沟乡小泉中心村、城关镇丽阳村；</div>
              <div class="patrol-object__item"> 第四巡察组：上户沟乡东湾中心村、滋泥泉子镇街北中心村、城关镇南湾村；</div>
			  <div class="patrol-object__item"> 第五巡察组：上户沟乡底沟中心村、九运街镇八运村、三工河乡大泉中心村；</div>
			  <div class="patrol-object__item"> 第六巡察组：上户沟乡西沟村、九运街镇黄土梁南中心村、三工河乡花儿沟村；</div>
            </div>
            <!-- 第一巡察组：城关镇良繁中心村、冰湖村、黄鸭坑村；
            第二巡察组：城关镇头工中心村、城北路社区、城北村；
            第三巡察组：城关镇龙王庙社区、龙王庙西村、龙王庙村；
            第四巡察组：城关镇坂干梁村、河南庄子村、城南村；
            第五巡察组：阜新街道文化路社区、大桥社区,城关镇四十户村； -->
          </van-cell>
          
          <div class="btn-box" style="margin: 16px 30px; text-align: right">
            <router-link :to="{ name: 'patrolNotice',params: {id: 1} }" >
              <van-button type="danger" round icon-position="right" icon="arrow">立即进入</van-button>
            </router-link>
          </div>
          
        </div>
      </Box>
      <!-- 底部内容 -->
      <Footer />
    </div>

  </div>
</template>

<script>
import Box from '../supervise/components/Box'
export default {
  name: 'Welcome',
  components: { Box }
}
</script>

<style lang="less" scope>
  .title-custom-style {
    flex: 0 0 25%;
  }
  .title {
    color: #fdc677;
    font-size: 15px;
    font-weight: 500; 
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
      width: 20%;
    }
    &__text {
      padding: 0 10px;
    }
  }

  .patrol-object {
    line-height: 1.5;
    font-size: 13px;

    &__item{
      padding: 3px;
    }
  }
</style>
